<template>
  <div>
    <modal
      name="modal-controlkm-detail"
      :width="`90%`"
      :height="`auto`"
      scrollable
      @opened="onModalOpened"
    >
      <v-card>
        <v-card-text class="text--primary pt-8">
          <v-row v-if="selected" class="text-center">
            <v-col cols="12" xs="12" sm="6">
              <h5>
                GPV: {{ `${selected?.user.name} ${selected?.user.surname}` }}
              </h5>
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <h5 class="text-left">
                Date:
                {{
                  selected?.date
                    ? moment(selected?.date).format("DD/MM/YYYY")
                    : ""
                }}
              </h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="6">
              <div ref="pos_google_map" class="pos_google_map"></div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" v-if="isLoading">
              <b-alert show variant="info">
                <b-icon
                  icon="arrow-counterclockwise"
                  animation="spin-reverse"
                ></b-icon>
                cargando datos...
              </b-alert>
            </v-col>
            <v-col v-else cols="12" xs="12" sm="12" md="6">
              <h6 class="controlkm-detail-label">Resumen de la Jornada</h6>
              <table
                class="table b-table table-striped table-bordered table-sm b-table-stacked-sm m-0 controlkms-detail"
              >
                <tr>
                  <th class="stats-th">General</th>
                  <th class="stats-th">Dato</th>
                </tr>
                <template v-for="(detailItem, detailKey) in mainDetails">
                  <tr :key="`main_detail_${detailKey}`">
                    <td>{{ detailItem.label }}</td>
                    <td>{{ detailItem.value }}</td>
                  </tr>
                </template>
              </table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" v-if="!isLoading">
              <h6 class="controlkm-detail-label">
                Detalle Clientes Visitados
              </h6>
              <table
                class="table b-table table-striped table-bordered table-sm b-table-stacked-sm m-0 controlkms-detail"
                v-if="
                  visitedPosList &&
                    visitedPosList.length > 0 &&
                    visitedPosTableList.length > 0
                "
              >
                <tr>
                  <th>Order of visit</th>
                  <th>Visit Type</th>
                  <th>Id POS / Id POS Brand</th>
                  <th>{{ $t("chain") }}</th>
                  <th>Full Address</th>
                  <th>HH:mm</th>
                  <th>Time In POS (HH:mm)</th>
                </tr>
                <template v-for="(posItem, posKey) in visitedPosTableList">
                  <tr :key="`visted_pos_item_${posKey}`">
                    <td>{{ posItem.orderIndex }}</td>
                    <td>{{ posItem.visitTypeLabel }}</td>
                    <td>
                      {{ formatIdPosAndIdPosBrands(posItem) }}
                    </td>
                    <td>{{ posItem.po?.chain?.name ?? "" }}</td>
                    <td>{{ posItem.address_label }}</td>
                    <td>
                      {{
                        posItem.startTimeStamp
                          ? moment(posItem.startTimeStamp).format("HH:mm")
                          : ""
                      }}
                    </td>
                    <td>
                      {{
                        get_duration_hhmm(
                          posItem.startTimeStamp,
                          posItem.endTimeStamp
                        )
                      }}
                    </td>
                  </tr>
                </template>
              </table>

              <b-alert
                show
                variant="warning"
                v-else-if="visitedPosTableList.length === 0"
              >
                ¡Datos vacíos!...
              </b-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.stop="hideModal()">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import moment from "moment";

export default {
  name: "DialogControlKMDetail",
  components: {},
  data: function() {
    return {
      isLoading: false,
      selected: null,
      mainDetails: [],
      visitedPosList: [],
      optimizedPosList: [],
      details_google_map: null,
      limitPosCountPerDirection: 22
    };
  },
  watch: {},
  computed: {
    visitedPosTableList() {
      let data = [];
      if (this.visitedPosList && this.visitedPosList.length > 0) {
        data = this.visitedPosList.filter(el => el.po);
      }
      return data;
    },
    columns() {
      return [
        {
          text: `Total KM GPS`,
          value: "realTotalKMFormated",
          sortable: false,
          width: "200px"
        },
        {
          text: `TOTAL KM ${this.$t("Optimized")}`,
          value: "optimizedRealTotalKMFormated",
          sortable: false,
          width: "200px"
        },
        {
          text: `${this.$t("Difference")}`,
          value: "differenceFormated",
          sortable: false,
          width: "200px"
        },
        {
          text: `${this.$t("Final KM Started by GPV")}`,
          value: "finalKMByGPV",
          sortable: false,
          width: "200px"
        },
        {
          text: this.$t("comments"),
          value: "gpvComments",
          sortable: false,
          width: "200px"
        }
      ];
    }
  },
  methods: {
    moment,
    showModal(selectedRow) {
      this.isLoading = true;
      this.selected = selectedRow;
      this.$modal.show("modal-controlkm-detail");
      console.log("this.selected - ", this.selected);
    },
    hideModal() {
      this.$modal.hide("modal-controlkm-detail");
    },
    onModalOpened() {
      this.initMap();
      this.init();
    },
    initMap() {
      var mapOptions = {
        // zoom: 14,
        // center: haight,
      };
      this.details_google_map = new window.google.maps.Map(
        this.$refs.pos_google_map,
        mapOptions
      );
    },
    renderDirections(result, color) {
      let renderOption = {};
      if (color) {
        renderOption.polylineOptions = { strokeColor: color };
      }
      var directionsRenderer = new window.google.maps.DirectionsRenderer(
        renderOption
      );
      directionsRenderer.setMap(this.details_google_map);
      directionsRenderer.setDirections(result);
    },
    requestDirections(pos_start, pos_end, waypoints, currentComponent, color) {
      console.log(
        "pos_start, pos_end, waypoints - ",
        pos_start,
        pos_end,
        waypoints
      );
      const directionsService = new window.google.maps.DirectionsService();
      var request = {
        origin: pos_start,
        destination: pos_end,
        // Note that JavaScript allows us to access the constant
        // using square brackets and a string value as its
        // "property."
        travelMode: window.google.maps.TravelMode["DRIVING"]
      };
      if (waypoints.length > 0) {
        request.waypoints = waypoints;
      }
      directionsService.route(request, function(result) {
        currentComponent.renderDirections(result, color);
      });
    },
    formatKMs(km_value) {
      return !isNaN(km_value) && km_value !== null
        ? `${this.number_format_es(km_value / 1000)} KM`
        : "";
    },
    formatIdPosAndIdPosBrands(posItem) {
      let ret = posItem.posId;
      let idPosBrands = [];
      if (posItem?.po && posItem.po.posbrands.length > 0) {
        idPosBrands = posItem.po.posbrands.map(el => el.idPosBrand);
      }
      if (idPosBrands.length > 0) {
        ret += ` / ${idPosBrands.join(",")}`;
      }
      return ret;
    },
    async init() {
      this.mainDetails = [];
      if (this.selected && this.selected?.id) {
        const {
          user,
          realTotalKM,
          optimizedRealTotalKM,
          finalKMByGPV,
          gpvComments,
          worksessionKmApproverName,
          approvalKMstatus_label,
          finalKMByResponsable
        } = this.selected;
        let pushItem = {};
        if (user) {
          pushItem.label = "GPV Ruta";
          pushItem.value = `${user.name} ${user.surname}`;
          this.mainDetails.push(pushItem);
        }
        this.mainDetails.push({
          label: `Total KM GPS`,
          value: this.formatKMs(realTotalKM)
        });
        this.mainDetails.push({
          label: `Total KM Optimized`,
          value: this.formatKMs(optimizedRealTotalKM)
        });
        this.mainDetails.push({
          label: this.$t("Difference"),
          value: this.formatKMs(realTotalKM - optimizedRealTotalKM)
        });
        this.mainDetails.push({
          label: this.$t("Final KM Started by GPV"),
          value: `${this.number_format_es(finalKMByGPV)} KM`
        });
        this.mainDetails.push({
          label: `GPV ${this.$t("comments")}`,
          value: gpvComments
        });
        this.mainDetails.push({
          label: this.$t("expensesParentUserName"),
          value: worksessionKmApproverName
        });
        this.mainDetails.push({
          label: this.$t("status"),
          value: approvalKMstatus_label
        });
        this.mainDetails.push({
          label: `Final KM`,
          value: `${this.number_format_es(finalKMByResponsable)} KM`
        });

        try {
          const {
            visitedPosList,
            // optimizedPosList,
            success
          } = await ApiService.get(
            `salesforce/controlkms/getdetail/${this.selected.id}`
          );
          if (success) {
            this.visitedPosList = visitedPosList;
            if (this.visitedPosList && this.visitedPosList.length > 0) {
              let posListLength = this.visitedPosList.length;
              let drawList = [];
              let directionIndex = 0;
              for (let i = 0; i < posListLength; i++) {
                if (!drawList[directionIndex]) drawList[directionIndex] = [];
                drawList[directionIndex].push(this.visitedPosList[i]);
                if (
                  i % this.limitPosCountPerDirection ===
                  this.limitPosCountPerDirection - 1
                ) {
                  directionIndex++;
                }
              }
              console.log(drawList);
              for (let i = 0; i < drawList.length; i++) {
                let drawListItemLength = drawList[i].length;
                let pos_start = drawList[i][0];
                let pos_end = drawList[i][drawListItemLength - 1];
                let startPoint = new window.google.maps.LatLng(
                  pos_start.lat,
                  pos_start.lng
                );
                let endPoint = new window.google.maps.LatLng(
                  pos_end.lat,
                  pos_end.lng
                );
                let waypoints = [];
                if (drawListItemLength > 2) {
                  for (let j = 1; j < drawListItemLength - 1; j++) {
                    let posItem = drawList[i][j];
                    let posItemPoint = new window.google.maps.LatLng(
                      posItem.lat,
                      posItem.lng
                    );
                    waypoints.push({ location: posItemPoint });
                  }
                }
                this.requestDirections(
                  startPoint,
                  endPoint,
                  waypoints,
                  this,
                  null
                );
              }
            }
            let optimizedPosList = [];
            this.visitedPosList.map((el, index) => {
              let optimized_item = { ...el };
              if (
                (el?.po &&
                  el?.worksession_po &&
                  el.worksession_po.visitType === "PRESENT") ||
                index === 0 ||
                index === this.visitedPosList.length
              ) {
                optimizedPosList.push(optimized_item);
              }
            });
            this.optimizedPosList = optimizedPosList;
            if (this.optimizedPosList && this.optimizedPosList.length > 0) {
              let posListLength = this.optimizedPosList.length;
              let pos_start = this.optimizedPosList[0];
              let pos_end = this.optimizedPosList[posListLength - 1];
              let startPoint = new window.google.maps.LatLng(
                pos_start.lat,
                pos_start.lng
              );
              let endPoint = new window.google.maps.LatLng(
                pos_end.lat,
                pos_end.lng
              );
              let waypoints = [];
              if (posListLength > 2) {
                for (let i = 1; i < posListLength - 1; i++) {
                  let posItem = this.optimizedPosList[i];
                  let posItemPoint = new window.google.maps.LatLng(
                    posItem.lat,
                    posItem.lng
                  );
                  waypoints.push({ location: posItemPoint });
                }
              }
              this.requestDirections(
                startPoint,
                endPoint,
                waypoints,
                this,
                "#00ff00"
              );
            }
          }
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
        }
      } else {
        logError("Network error !");
      }
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style>
.controlkms-detail tr th {
  background: #203864;
  color: #fff;
}
.controlkms-detail tr th,
.controlkms-detail tr td {
  vertical-align: middle;
}
h6.controlkm-detail-label {
  background: #b4c7e7;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  font-size: 1.2rem;
  text-align: center;
}
.pos_google_map {
  width: 100%;
  height: 60vh;
}
</style>
