<template>
  <div>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <span class="text-h5">{{ `${$t("salesforce.Edit")}` }}</span>
          </v-card-title>

          <v-card-text class="text--primary">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("User") }} : {{ selected.user_name }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">{{ $t("Date") }} : {{ selected.date }}</h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    KM GPS :
                    {{ formatKMs(selected.realTotalKM) }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    KM Optimized :
                    {{ formatKMs(selected.optimizedRealTotalKM) }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <h6 class="my-2">
                    {{ $t("Difference") }} :
                    {{ formatKMs(selected.differenceKM) }}
                  </h6>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="selected.finalKMByGPV"
                    v-bind:label="$t('Final KM Started by GPV')"
                    :type="'number'"
                    :readonly="!selected.isGPVEditing"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="selected.gpvComments"
                    v-bind:label="`GPV ${this.$t('comments')}`"
                    :readonly="!selected.isGPVEditing"
                    v-bind:class="
                      'gpv_comment_text ' +
                        (!selected.isGPVEditing ? 'readonly' : '')
                    "
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="selected.responsableId">
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ $t("expensesParentUserName") }} :
                    {{ selected.worksessionKmApproverName }}
                  </h6>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="selected.responsableComments"
                    v-bind:label="
                      `${this.$t('expensesParentUserName')} ${this.$t(
                        'comments'
                      )}`
                    "
                    :readonly="!selected.isResponsableEditing"
                    v-bind:class="
                      'gpv_comment_text ' +
                        (!selected.isResponsableEditing ? 'readonly' : '')
                    "
                    :required="isRequiredResponsableComments"
                    :rules="isRequiredResponsableComments ? requiredRules : []"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    :label="
                      `${this.$t('approved')} by ${this.$t(
                        'salesforce.Parent'
                      )}`
                    "
                    v-model="selected.approvalKMstatus"
                    :items="approval_status"
                    item-text="label"
                    item-value="value"
                    filled
                    :readonly="!selected.isResponsableEditing"
                    :required="selected.isResponsableEditing"
                    :rules="selected.isResponsableEditing ? requiredRules : []"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="selected.finalKMByResponsable"
                    label="Final KM"
                    :type="'number'"
                    :readonly="!selected.isResponsableEditing"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
              v-if="selected.isGPVEditing || selected.isResponsableEditing"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("Control Kilometers") }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="warning" dark @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefControlKms"
              id="datatable_control_kms"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <DialogControlKMDetail ref="dialogControlKMDetail" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import moment from "moment";
import DialogControlKMDetail from "./DialogControlKMDetail.vue";

export default {
  name: "SalesForceControlKms",
  components: {
    DialogControlKMDetail
  },
  data: function() {
    return {
      approval_status: [],
      filterlist_fetch_url: "salesforce/controlkms/getfilterlist",
      filterable_col_list: [],
      filterCriteria: {
        date: {},
        user: {},
        realTotalKM: {},
        optimizedRealTotalKM: {},
        differenceKM: {},
        finalKMByGPV: {},
        gpvComments: "",
        worksessionKmApprover: {},
        responsableComments: "",
        approvalKMstatus: {},
        finalKMByResponsable: {}
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (row.approvalKMstatus === "ALERT") {
            return "table-body-cell-incidence";
          } else if (row.approvalKMstatus === "NOT_OK") {
            return "table-body-cell-not-ok";
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,
      totaldata: 0,
      data: [],
      statistics_data: {},

      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      isSaving: false,
      selectedRow: null
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isGPV",
      "isAdmin",
      "isSubAdmin",
      "user"
    ]),
    isRequiredResponsableComments() {
      if (this.selected && this.selected.isResponsableEditing) {
        if (["OK_REVISED", "NOT_OK"].includes(this.selected.approvalKMstatus)) {
          return true;
        }
      }
      return false;
    },
    col_userId() {
      return {
        key: "userId",
        title: "ID " + this.$t("User"),
        field: "userId",
        align: "left",
        width: 120,
        sortBy: ""
      };
    },
    col_user() {
      return {
        key: "user",
        title: this.$t("User"),
        field: "user",
        align: "left",
        width: 200,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user_name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["user"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`user`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["user"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "user")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_date() {
      return {
        key: "date",
        title: this.$t("Date"),
        field: "date",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        // renderBodyCell: ({ row, column, rowIndex }, h) => {
        //   return <span>{row.worksessionDate}</span>;
        // },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["from"]}
                  value={this.filterCriteria["date"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["date"]["to"]}
                  value={this.filterCriteria["date"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "date")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_realTotalKM() {
      return {
        key: "realTotalKM",
        title: "KM GPS",
        field: "realTotalKM",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{this.formatKMs(row.realTotalKM)}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["realTotalKM"]["from"]}
                  value={this.filterCriteria["realTotalKM"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["realTotalKM"]["to"]}
                  value={this.filterCriteria["realTotalKM"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "realTotalKM")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_optimizedRealTotalKM() {
      return {
        key: "optimizedRealTotalKM",
        title: "KM Optimized",
        field: "optimizedRealTotalKM",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{this.formatKMs(row.optimizedRealTotalKM)}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["optimizedRealTotalKM"]["from"]}
                  value={this.filterCriteria["optimizedRealTotalKM"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["optimizedRealTotalKM"]["to"]}
                  value={this.filterCriteria["optimizedRealTotalKM"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "optimizedRealTotalKM")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_differenceKM() {
      return {
        key: "differenceKM",
        title: this.$t("Difference"),
        field: "differenceKM",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{this.formatKMs(row.differenceKM)}</span>;
        }
        // filterCustom: {
        //   defaultVisible: false,
        //   render: ({ showFn, closeFn }, h) => {
        //     return (
        //       <div class="custom-filter">
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={this.filterCriteria["differenceKM"]["from"]}
        //           value={this.filterCriteria["differenceKM"]["from"]}
        //           placeholder="From - "
        //         />
        //         <br />
        //         <input
        //           type="number"
        //           class="filter_date_picker"
        //           v-model={this.filterCriteria["differenceKM"]["to"]}
        //           value={this.filterCriteria["differenceKM"]["to"]}
        //           placeholder="To - "
        //         />
        //         <div class="custom-filter-operation">
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchCancel(closeFn, "differenceKM")}
        //           >
        //             {this.$t("cancel")}
        //           </v-btn>
        //           <v-btn
        //             color="blue darken-1"
        //             text
        //             on-click={() => this.searchConfirm(closeFn)}
        //           >
        //             {this.$t("Search")}
        //           </v-btn>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
      };
    },
    col_finalKMByGPV() {
      return {
        key: "finalKMByGPV",
        title: this.$t("Final KM Started by GPV"),
        field: "finalKMByGPV",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["finalKMByGPV"]["from"]}
                  value={this.filterCriteria["finalKMByGPV"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["finalKMByGPV"]["to"]}
                  value={this.filterCriteria["finalKMByGPV"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "finalKMByGPV")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_gpvComments() {
      return {
        key: "gpvComments",
        title: `GPV ${this.$t("comments")}`,
        field: "gpvComments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["gpvComments"]}
                  on-input={value =>
                    (this.filterCriteria["gpvComments"] = value)
                  }
                  placeholder="Search Comentarios"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "gpvComments")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_worksessionKmApprover() {
      return {
        key: "worksessionKmApprover",
        title: this.$t("expensesParentUserName"),
        field: "worksessionKmApprover",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.worksessionKmApproverName}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["worksessionKmApprover"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`worksessionKmApprover`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["worksessionKmApprover"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "worksessionKmApprover")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_responsableComments() {
      return {
        key: "responsableComments",
        title: `${this.$t("expensesParentUserName")} ${this.$t("comments")}`,
        field: "responsableComments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <v-text-field
                  value={this.filterCriteria["responsableComments"]}
                  on-input={value =>
                    (this.filterCriteria["responsableComments"] = value)
                  }
                  placeholder="Search Comentarios"
                ></v-text-field>
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "responsableComments")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_approvalKMstatus() {
      return {
        key: "approvalKMstatus",
        title: `${this.$t("status")}`,
        field: "approvalKMstatus",
        align: "left",
        sortBy: "",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.approvalKMstatus_label}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["approvalKMstatus"]}
                  dataList={this.approval_status}
                  filterCriteria={this.filterCriteria["approvalKMstatus"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "approvalKMstatus")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_finalKMByResponsable() {
      return {
        key: "finalKMByResponsable",
        title: `Final KM`,
        field: "finalKMByResponsable",
        align: "left",
        width: 150,
        sortBy: "",
        // renderBodyCell: ({ row, column, rowIndex }, h) => {
        //   return <span>{row.worksessionDate}</span>;
        // },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["finalKMByResponsable"]["from"]}
                  value={this.filterCriteria["finalKMByResponsable"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["finalKMByResponsable"]["to"]}
                  value={this.filterCriteria["finalKMByResponsable"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "finalKMByResponsable")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    columns() {
      return [
        this.col_userId,
        this.col_user,
        this.col_date,
        this.col_realTotalKM,
        this.col_optimizedRealTotalKM,
        this.col_differenceKM,
        this.col_finalKMByGPV,
        this.col_gpvComments,
        this.col_worksessionKmApprover,
        this.col_responsableComments,
        this.col_approvalKMstatus,
        this.col_finalKMByResponsable,
        {
          key: "go",
          title: this.$t("go"),
          field: "go",
          align: "center",
          width: 80,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemGo(row)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        },
        {
          key: "actions",
          title: this.$t("edititem"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    formatKMs(km_value) {
      return !isNaN(km_value) && km_value !== null
        ? this.number_format_es(km_value / 1000)
        : "";
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (
        [
          "realTotalKM",
          "optimizedRealTotalKM",
          "finalKMByGPV",
          "finalKMByResponsable"
        ].includes(cancelFilterKey)
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else if (
        ["gpvComments", "responsableComments"].includes(cancelFilterKey)
      ) {
        this.filterCriteria[cancelFilterKey] = null;
      } else this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable_control_kms"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (
            [
              "realTotalKM",
              "optimizedRealTotalKM",
              "finalKMByGPV",
              "finalKMByResponsable"
            ].includes(key)
          ) {
            if (
              (value["from"] !== "" &&
                value["from"] !== null &&
                value["from"] !== undefined) ||
              (value["to"] !== "" &&
                value["to"] !== null &&
                value["to"] !== undefined)
            ) {
              filterModel.push({
                columnField: key,
                filterValue: value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#datatable_control_kms .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "salesforce/controlkms";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.data = resp.data;
        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        this.approval_status = resp.approval_status;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "salesforce/controlkms/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "data.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async onItemGo(row) {
      this.selectedRow = row;
      await this.$refs.dialogControlKMDetail.showModal(this.selectedRow);
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    createSelectedModel(item) {
      console.log("createSelectedModel - ", item);
      let model = {
        ...item,
        isGPVEditing: false,
        isResponsableEditing: false,
        isChecking: true
      };
      if (this.isAdmin || this.isSubAdmin) {
        model.isGPVEditing = true;
        model.isResponsableEditing = true;
      } else {
        if (model.userId !== this.user.id) {
          model.isGPVEditing = false;
          model.isResponsableEditing = true;
        } else {
          model.isGPVEditing = true;
          model.isResponsableEditing = false;
        }
      }
      console.log("createSelectedModel - ", model);
      return model;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        const {
          id,
          finalKMByGPV,
          gpvComments,
          responsableComments,
          finalKMByResponsable,
          approvalKMstatus
        } = item;
        let body = {
          finalKMByGPV,
          gpvComments,
          responsableComments,
          finalKMByResponsable,
          approvalKMstatus
        };
        if (id) {
          try {
            await ApiService.put(`salesforce/controlkms/${id}`, body);
            logInfo("Éxito !");
            this.showEdit = false;
            this.getDataFromApi();
            this.isSaving = false;
          } catch (error) {
            this.isSaving = false;
          }
        }
      }
    },
    init() {
      this.filterable_col_list = Object.keys(this.filterCriteria);
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
